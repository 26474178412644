<template>
<div >
<v-btn id="export"   @click="exportexcel()" color="success" dark class="mb-2">
          <v-icon dark>mdi-file-excel</v-icon>
        </v-btn>
<table id="exceltable" style="display:none;">
<thead>
  <tr>
            <td align="center" ><img border="0" :src="logo_url" width="50" height="50"></td>
            <td align="center" style="color:#000000;"><center>{{trust_name}}</center><br><b><center> {{collage_name}} </center></b><br><b> <center>{{collage_address}}</center></b></td>
            <td><b>{{report_name}}</b> </td>
            <td align="center" style="color:#000000;"><b>Date : </b>{{new Date().toISOString().slice(0, 10)}}</td>
            
     </tr>   
     
     <tr >
     <th v-for="(item,index) in headers" :key="index">
     <b>{{item.text}}</b>
     </th>
     </tr>    
     </thead>
     <tbody>
     <tr v-for="(item,index) in tabledata" :key="index">
     <td v-for="(item1,index1) in headers" :key="index1">
            {{item[item1.value]}}
     </td>
     </tr>
     </tbody>
     </table>
</div>
</template>
<script>
export default {
  props: ["headers","tabledata","report_name"],
  data: () => ({
  collage_address:"",
  logo_url:"",
  collage_name:"",
  trust_name:"",
    }),
   mounted() {
    this.logo_url=localStorage.getItem('logo_url');
    this.collage_address=localStorage.getItem('org_address');
    this.collage_name=localStorage.getItem('org_name');
     this.trust_name=localStorage.getItem('org_trust_name');
  },
  methods: {
    
    
    exportexcel() {
     
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        exclude_img: false,
        filename: "Library_report", //do not include extension
        fileext: ".xlsx", // file extension
        preserveColors: false
      });
    },
    }
};
</script>
